<template>
  <StandardModal
    v-if="showLocationSelectionModal"
    max-width="380px"
    name="locationSelectionModal"
    class="location-selection-modal"
    :fullscreen="false"
    position="br"
    @close="close()"
  >
    <template #title>
      <div>
        <span
          v-if="userLocationCountryName"
          class="location-selection-modal-title"
          v-html="
            locationInScopeCountries
              ? navigatorLocale === 'EN'
                ? $t('i18n.targetDeliveryInfoEn', { country: userLocationCountryName })
                : $t('i18n.targetDeliveryInfo', { country: userLocationCountryName }, { locale: navigatorLocale })
              : navigatorLocale === 'EN'
                ? $t('i18n.wrongLocationEn', { country: userLocationCountryName })
                : $t('i18n.wrongLocation', { country: userLocationCountryName }, { locale: navigatorLocale })
          "
        />
      </div>
    </template>
    <template #content>
      <div v-if="!locationInScopeCountries" class="location-selection-modal-content">
        <div class="location-selection-modal-dropdown">
          <SelectField
            :selected-value="selectedHost?.intlName || countriesList[0]?.intlName"
            class-variant="bg-grey radius-md pointer"
            @click="showMenu = !showMenu"
          >
            <template #icon>
              <div class="icon-check u-bg-dark-blue" />
            </template>
          </SelectField>
          <div v-if="showMenu" class="location-selection-modal-dropdown-options">
            <div
              v-for="c in countriesList"
              :key="c?.code"
              :value="c"
              class="location-selection-modal-dropdown-options-option"
              @click="selectAndClose(c)"
            >
              <div class="icon-check" :class="{ 'u-bg-dark-blue': selectedHost?.code === c?.code }" />
              <div class="u-d-f u-ai-c u-f-gap-sm">
                {{ c.intlName }}
              </div>
            </div>
          </div>
        </div>
        <MainButton
          tag="a"
          class-variant="btn-medium"
          :label="$t('i18n.continue')"
          @click="selectHost(selectedHost, hostUrl(selectedHost?.host))"
        />
      </div>
    </template>
    <template #options>
      <div v-if="locationInScopeCountries">
        <div class="location-selection-modal-options">
          <MainButton
            tag="a"
            class-variant="btn-bg-grey-blue btn-hover-dark-blue btn-medium"
            :label="`${countriesList[1]?.intlName}`"
            @click="selectHost(countriesList[1])"
          />
          <MainButton
            tag="a"
            :label="`${countriesList[0]?.intlName}`"
            :link="hostUrl(countriesList[0]?.host)"
            class-variant="btn-primary full-width btn-medium"
            @click="selectHost(countriesList[0])"
          />
        </div>
      </div>
    </template>
  </StandardModal>
</template>

<script>
import i18nMx from '@/mixins/i18n-mixin'
import { nextTick } from 'vue'

import StandardModal from '@/components/Modals/StandardModal/StandardModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import SelectField from '@/components/SelectField/SelectField'

export default {
  name: 'LocationSelectionModal',
  components: {
    StandardModal,
    MainButton,
    SelectField,
  },
  mixins: [i18nMx],
  emits: ['modalOpen_locationSelectionModal', 'modalClose_locationSelectionModal'],
  data() {
    return {
      showLocationSelectionModal: false,
      userLocation: null,
      selectedHost: null,
      showMenu: false,
    }
  },
  computed: {
    countriesList() {
      const { tld } = useCurrentLocale()
      // determines buttons list
      if (this.locationInScopeCountries && this.userLocation) {
        return [
          this.countries.find(country => country.code.toUpperCase() === this.userLocation),
          this.countries.find(country => country.code.toUpperCase() === tld),
        ]
      }
      return this.countries
    },
    locationInScopeCountries() {
      if (this.userLocation) {
        return this.countries.map(x => x.code).includes(this.userLocation?.toLowerCase())
      }
      return true
    },
    userLocationCountryName() {
      const { tld } = useCurrentLocale()
      if (this.userLocation) {
        return this.isoNameCountries[this.userLocation]
      }
      return this.isoNameCountries[tld]
    },
    navigatorLocale() {
      let locale = null
      const { locale: currentLocale } = useCurrentLocale()
      if (import.meta.client) {
        if (navigator?.language?.split('-')?.length) {
          locale = navigator?.language?.split('-')?.shift()?.toUpperCase() || currentLocale
        } else {
          locale = navigator?.language?.toUpperCase() || currentLocale
        }
      }
      return this.$i18n.localeCodes.includes(locale.toLowerCase()) ? locale : 'EN'
    },
    navigatorLocation() {
      const { tld } = useCurrentLocale()
      if (import.meta.client) {
        if (navigator?.language?.split('-')?.length) {
          return navigator?.language?.split('-')?.pop()?.toUpperCase() || tld || 'FR'
        } else {
          return navigator?.language?.toUpperCase() || tld || 'FR'
        }
      }
      // default return TLD
      return tld
    },
  },
  mounted() {
    const { country } = useCurrentLocale()
    this.initGeoIp()
    this.selectedHost = country
  },
  methods: {
    initGeoIp() {
      const { country } = useCurrentLocale()
      const countryIpCookie = useCookie('x-country-ip', {
        maxAge: 60 * 60 * 24 * 365,
        domain: `.${country?.host}`,
      })
      const locationModalDisabledCookie = useCookie('x-location_modal_disabled')
      // avoid multiple calls for same user by using cookies
      if (!countryIpCookie.value && !locationModalDisabledCookie.value) {
        // Init maxmind script
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.id = 'maxmind-geoip'
        script.onload = setTimeout(() => this.getGeoIp(), 500)
        script.src = 'https://geoip-js.com/js/apis/geoip2/v2.1/geoip2.js'
        const first = document.getElementsByTagName('script')[0]
        first.parentNode.insertBefore(script, first)
        // To avoid CORS issue in local env, use extension like https://chromewebstore.google.com/detail/moesif-origincors-changer/digfbfaphojjndkpccljibejjbppifbc?hl=fr
      } else {
        this.openModal()
      }
    },
    async getGeoIp() {
      let countryCookie = null
      if (window.geoip2) {
        try {
          await window.geoip2.country(
            data => {
              // console.log('data', data)
              if (data?.country) {
                const { country } = useCurrentLocale()
                countryCookie = data.country.iso_code
                const countryIpCookie = useCookie('x-country-ip', {
                  maxAge: 60 * 60 * 24 * 365,
                  domain: `.${country?.host}`,
                })
                countryIpCookie.value = data.country.iso_code
                this.openModal(countryCookie)
              }
            },
            error => {
              console.log(error)
              throw error
            }
          )
        } catch (error) {
          console.log(error)
          this.$sentry.captureException(error)
        }
      } else {
        this.openModal(countryCookie)
      }
    },
    openModal(countryCookie) {
      const { country, tld } = useCurrentLocale()
      const countryIpCookie = useCookie('x-country-ip', {
        maxAge: 60 * 60 * 24 * 365,
        domain: `.${country?.host}`,
      })
      const locationModalDisabledCookie = useCookie('x-location_modal_disabled')
      const selectedHostCookie = useCookie('x-selectedHost', {
        maxAge: 60 * 60 * 24 * 365,
        domain: `.${country.host}`,
      })
      nextTick(() => {
        const locationInRightHost =
          (countryIpCookie.value || countryCookie) === tld ||
          (!(countryIpCookie.value || countryCookie) && this.navigatorLocation === tld)
        this.userLocation = countryIpCookie.value || countryCookie || this.navigatorLocation
        if (!locationInRightHost && !selectedHostCookie.value && !locationModalDisabledCookie.value) {
          this.showLocationSelectionModal = true
          nextTick(() => {
            this.$bus.emit('modalOpen_locationSelectionModal', 'locationSelectionModal')
          })
        }
      })
    },
    afterSwitchLocale() {
      return this.close()
    },
    selectHost(country, url) {
      const { tld } = useCurrentLocale()
      const selectedHostCookie = useCookie('x-selectedHost', { maxAge: 60 * 60 * 24 * 365, domain: `.${country.host}` })
      selectedHostCookie.value = country.host
      if (url && country?.code?.toUpperCase() !== tld) {
        url += `?source_host=${tld}`
        this.openLink(url, false, ``)
      }
      this.close()
    },
    hostUrl(host) {
      return `${window.location.protocol}//www.${host}`
    },
    close() {
      // if close modal, set selectedHost to current host for 2 week
      const { country } = useCurrentLocale()
      const selectedHostCookie = useCookie('x-selectedHost', {
        maxAge: 60 * 60 * 24 * 14,
        domain: `.${country.host}`,
      })
      selectedHostCookie.value = country.host
      this.$bus.emit('modalClose_locationSelectionModal', 'locationSelectionModal')
    },
    openLink(url, blank = true) {
      const link = document.createElement('a')
      link.href = url
      if (blank) {
        link.setAttribute('target', '_blank')
      }
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    selectAndClose(c) {
      this.selectedHost = c
      this.showMenu = false
    },
  },
}
</script>

<style lang="scss">
.location-selection-modal {
  .modal-wrapper .modal-container .modal-header .modal-header-title {
    font-size: pxToRem(15px) !important;
    @include mq($mq-md) {
      font-size: pxToRem(12px) !important;
    }
  }
  &-title {
    font-weight: 400;
    & span {
      color: var(--primary);
    }
  }
  &-description {
    padding: $spacing-md;
    & span {
      color: var(--primary);
    }
  }
  &-selection {
    border-bottom: 1px solid var(--light-grey);
    padding: $spacing-md;
    cursor: pointer;
    a {
      align-items: center;
      color: var(--dark-blue);
      display: flex;
      gap: $spacing-sm;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &-options {
    align-items: center;
    display: flex;
    gap: $spacing-md;
    justify-content: space-between;
    padding: $spacing-md;
    &-button,
    a {
      flex: 1;
      width: 50%;
    }
  }
  &-content {
    display: flex;
    gap: $spacing-md;
    padding: $spacing-md;
  }
  &-dropdown {
    flex-grow: 1;
    position: relative;
    .sp-select-icon-left {
      padding-left: 10px;
      padding-top: 5px;
      margin-right: 5px;
    }
    &-options {
      background-color: var(--bg-grey);
      border-radius: $spacing-xs;
      bottom: 0;
      position: absolute;
      width: 100%;
      &-option {
        cursor: pointer;
        display: flex;
        gap: $spacing-xs;
        padding: $spacing-xs $spacing-sm;
        font-size: pxToRem(12px);
        font-weight: 600;
      }
    }
  }
  .modal-container {
    padding: 0 !important;
  }
  .modal-body {
    margin: 0 !important;
    font-size: pxToRem(15px);
  }
  .modal-header {
    margin-bottom: 0 !important;
    padding: $spacing-md $spacing-md 0 $spacing-md;
  }
}
</style>
